<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/03-4iDAS整合数据分析系统.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">iDAS整合数据分析系统</div>
            <div class="mobile-head-explain-content">韩国某知名化妆品集团</div>
        </div>
        <div class="description">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;">
            <el-collapse-transition>
                <div v-if="showDescription">
                    <div class="description-box">客户公司是全球最大的化妆品企业之一，从上世纪90年代起开展全球品牌战略，业务遍布五大洲超过40多个
                国家和地区。伴随自身业务快速发展的同时，需要通过数据与业务的深度融合促进业务创新，实现数据价值，现有的数据仓库架构分散，没有统一的指
                标体系，不能满足业务的需求，面临着诸多挑战。项目针对销售、促销、库存的分析体系，形成较为完善的数据仓库，整合内部、外部、手工数据，帮
                助业务部门整合分析指标，自助分析数据，除此之外，还可自助制作Dashboard，节省制作时间，充分挖掘数据。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="title2">内部</div>
            <div class="ware-box1">
                <div class="title1">
                    可靠的外部数据获取困难
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    多格式数据的清洗整合
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    缺少完整的分析模型
                </div>
            </div>
            <div class="title2" style="margin-top:2rem">外部</div>
            <div class="ware-box2">
                <div class="title1">
                    数仓架构分散，数据分散在多个渠道多个品牌的系统中，由不同的业务系统负责不同的业务模块的数据架构设计及实施
                </div>
            </div>
            <div class="ware-box2">
                <div class="title1">
                    数据仓库比较传统，缺失非结构化数据分析手段，跑批性能和报表查询性能不能满足业务的需求
                </div>
            </div>
            <div class="ware-box2">
                <div class="title1">
                    按照报表需求，分别进行统计加工，同一指标在不同报表间存在差异
                </div>
            </div>
            <div class="ware-box2">
                <div class="title1">
                    数据需求由多个系统实现，修改繁琐，交付周期长
                </div>
            </div>
            <div class="ware-box2" style="margin-bottom:5rem">
                <div class="title1">
                    数据量越来越大，现有系统缺少弹性
                </div>
            </div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <div class="purchase-box">
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-3架构设计.svg"/>
                    <div>
                        <div class="title1">架构设计</div>
                        <div class="title2">根据比孚已有的行业经验与Amazon实施经验，结合其数据情况，为该企业规划了一套循序渐进的、基于
                            Amazon新型数据架构。最终根据企业IT环境实际情况，协助完成物理架构落地</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-3指标整理.svg"/>
                    <div>
                        <div class="title1">指标整理</div>
                        <div class="title2">对数据源进行探查，分析现有指标逻辑，协调各部门，制定一套完整的指标体系</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-3模型设计.svg"/>
                    <div>
                        <div class="title1">模型设计</div>
                        <div class="title2">基于企业业务实质，抽象和设计概念数据模型，明确业务概念和关系。划分清晰的数据存储层次与主要
                            功能，定义数据管理规范</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-3报表实施.svg"/>
                    <div>
                        <div class="title1">报表实施</div>
                        <div class="title2">根据不同部门的报表需求，合理制定报表体系。充分利用Amazon Redshift的性能，优化报表查询性能</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1" style="margin-bottom:5rem">
            <div style="margin-bottom:10rem">
                <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>数据平台</div>
                <div class="purchase-content" >云上数据湖容纳了大量丰富数据，为提供各种数据服务成为了可能；</div>
                <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>指标体系</div>
                <div class="purchase-content" >制定一套完整的指标体系满足不同的业务需求，统一部门间的业务认识；</div>
                <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>模型设计</div>
                <div class="purchase-content" >企业数据仓库的架构与模型满足未来业务发展变化的需要；</div>
                <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>数据性能</div>
                <div class="purchase-content" >1.ETL整体过程整体执行效率较线下的数据仓库提升倍；<br/>
                    2.报表查询平均时间较之前提升数倍。</div>
            </div>
                
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
    .title2{
        // position: absolute;
        // width: 4rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #005395;
        line-height: 2.8rem;
        margin: 1.9rem 0 0 3rem;
        cursor: pointer;
        .line{
            width: 6rem;
            height: 2rem;
            border-bottom: 3px solid #005395;
            border-radius: 50%;
            margin: -1rem 0 0 0rem;
        }
    }
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-box2{
    width: 66.8rem;
    height: 20rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: 43.5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 100%;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    
    .card{
        width: 52.8rem;
        height: 30rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 4rem;

            margin: 2rem 0 0 2.1rem;
        }
        .title2{
            width: 43.2rem;
            height: 7.2rem;
            font-size: 2.5rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 4rem;

            margin: 2rem 0 0 2.1rem;
        }
    }
}
.purchase-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;

    margin-top: 2rem;
}
.purchase-doit{
    color: #005395;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;

    margin: 1rem 0 0 3.5rem;
}
</style>